<template>
  <loading v-if="$_.isEmpty(site) || loading" />

  <div v-else>
    <!-- Site FQDN -->
    <apart class="is-size-7">
      <p class="has-text-grey">Site</p>
      <p class="has-overflow-ellipsis-left">
        <a
          :href="site.url"
          target="_blank"
          class="has-text-dark has-margin-x-25"
        >
          <b-icon icon="external-link-alt" size="is-small" />
        </a>
        <router-link :to="{ path: `/sites/${site._id}` }">
          <u>{{ site.fqdn }}</u>
        </router-link>
      </p>
    </apart>
    <!-- Date created -->
    <apart class="is-size-7">
      <p class="has-text-grey">Created</p>
      <p>
        <strong>{{
          $moment(site.dateCreated.toDate()).format("Do MMM, YYYY")
        }}</strong>
      </p>
    </apart>
    <!-- Software -->
    <apart class="is-size-7">
      <p class="has-text-grey">Software</p>
      <p>
        <strong>{{ site.software }}</strong>
      </p>
    </apart>
    <!-- Subscription -->
    <apart class="is-size-7">
      <p class="has-text-grey">Subscription</p>
      <router-link
        v-if="site.isSubscribed && $store.getters['user/isAdmin']()"
        :to="{
          path: `/admin/contracts/${$_.get(subscription, 'id', site._id)}`
        }"
      >
        <u>Manage</u>
      </router-link>
      <strong v-else-if="site.isSubscribed">Yes</strong>
      <span v-else>–</span>
    </apart>
    <!-- Credits -->
    <apart class="is-size-7">
      <p class="has-text-grey">Credits</p>
      <strong v-if="hasTaskCredits">{{ hasTaskCredits }}</strong>
      <span v-else>–</span>
    </apart>
    <!-- Tasks count -->
    <apart class="is-size-7">
      <p class="has-text-grey">Tasks</p>
      <strong v-if="(site.quota || {}).totalTasks">{{
        site.quota.totalTasks
      }}</strong>
      <span v-else>–</span>
    </apart>
    <!-- Show more toggle -->
    <p
      v-if="!showMore"
      class="is-size-7 has-text-grey has-text-centered has-cursor-pointer"
      @click="showMore = true"
    >
      <u>Show more</u>
    </p>
    <template v-else>
      <!-- Complexity -->
      <apart v-if="$store.getters['user/isAgent']()" class="is-size-7">
        <p class="has-text-grey">Complexity</p>
        <span>{{ $_.get(complexity, "name", "–") }}</span>
      </apart>
      <!-- Last audit -->
      <apart v-if="$store.getters['user/isAgent']()" class="is-size-7">
        <p class="has-text-grey">Last audit</p>
        <span>{{
          $_.has(site, "metadata.lastAuditDate")
            ? $moment(site.metadata.lastAuditDate.toDate()).calendar()
            : "–"
        }}</span>
      </apart>
      <!-- Hosting provider -->
      <apart class="is-size-7">
        <p class="has-text-grey">Hosting provider</p>
        <span>{{ $_.get(site, "metadata.hostingProvider", "–") }}</span>
      </apart>
      <!-- Domain registrar -->
      <apart class="is-size-7">
        <p class="has-text-grey">Domain registrar</p>
        <span>{{ $_.get(site, "metadata.domainRegistrar", "–") }}</span>
      </apart>
      <!-- DNS provider -->
      <apart class="is-size-7">
        <p class="has-text-grey">DNS provider</p>
        <span>{{ $_.get(site, "metadata.dnsProvider", "–") }}</span>
      </apart>
      <!-- Email provider -->
      <apart class="is-size-7">
        <p class="has-text-grey">Email provider</p>
        <span>{{ $_.get(site, "metadata.emailProvider", "–") }}</span>
      </apart>
      <!-- SSL provider -->
      <apart class="is-size-7">
        <p class="has-text-grey">SSL provider</p>
        <span>{{ $_.get(site, "metadata.sslProvider", "–") }}</span>
      </apart>
    </template>
  </div>
</template>

<script>
const complexities = require("@/data/siteComplexities.json");
export default {
  name: "SiteDetails",
  props: {
    site: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      loading: true,
      showMore: false,
      subscription: null
    };
  },
  computed: {
    hasTaskCredits() {
      return this.$store.getters["sites/site/hasTaskCredits"](this.site._id);
    },
    complexity() {
      return this.$_.toArray(complexities).find(
        i => i.id === this.$_.get(this.site, "metadata.complexity")
      );
    }
  },
  async created() {
    try {
      this.loading = true;
      const contract = await this.$store.dispatch("billing/findSubscription", {
        siteId: this.site._id
      });
      if (contract) this.subscription = contract;
    } finally {
      this.loading = false;
    }
  }
};
</script>
